.new-mention-tag {
    background-color: #1a73e8; /* Set the background color */
    color: #fff; /* Set the text color */
    padding: 5px 10px; /* Set padding for the tag */
    border-radius: 4px; /* Optional: Set border radius for rounded corners */
    font-size: 13px; /* Set the font size */
    font-weight: bold; /* Optional: Set font weight for bold text */
    display: inline-block; /* Display as inline block */
    margin-left: 15px; /* Optional: Set margin for spacing */
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

.tagInput {
  position: relative;
  border-style: none;
  border: none;
  display: block;
  flex: 1 1 auto;
  margin: 5px;
  min-height: 100%;
  font-size: 12pt;
  background-color: #f5f5f5;
}

.tagInputWrapper {
  display: flex;
  flex-flow: row wrap;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.tagInputWrapper :focus {
  outline: blue;
}

.tagsList {
  display: inline;
  padding: 0px;
  margin: 0px;
  margin-right: 5px;
  
  li {
    font-size: 9.5pt;
    display: inline-block;
    margin: 3px;
    padding: 3px 5px 3px 5px;
    border-radius: 4px;
    background: #ddd;
    color: #444;
    
    &.clickable:hover {
      background: #aaa;
      cursor: pointer;
      color: black;
    }
    
    a {
      text-decoration: none;
      color: #666;
      margin-left: 5px;
      &:hover {
        color: #dd3345;
      }

      padding: 0px 2px 0px 2px;
    }
  }
}
